// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import "./styles.scss";
// import Swiper core and required modules
import SwiperCore, { Autoplay, Grid, Navigation } from "swiper";
import { Comodidade } from "../Comodidade";

import ScrollAnimation from "react-animate-on-scroll";
import { comodidades } from "./comodidadesData";
// install Swiper modules
SwiperCore.use([Navigation]);

export function SwiperComodidade() {
  return (
    <>
      <ScrollAnimation animateIn="animate__fadeIn">
        <Swiper
          navigation={true}
          slidesPerView={3}
          spaceBetween={8}
          className="swipper-comodidade"
          autoplay={true}
          modules={[Navigation, Grid, Autoplay]}
          grid={{
            rows: 3,
            fill: "row",
          }}
          breakpoints={{
            200: {
              slidesPerView: 2,
            },
            630: {
              slidesPerView: 2,
            },

            767: {
              slidesPerView: 3,
            },

            1023: {
              slidesPerView: 3,
            },
          }}
        >
          {comodidades.map((comodidade) => (
            <SwiperSlide>
              <Comodidade
                key={comodidade.id}
                comodidadeImg={comodidade.comodidadeImg}
                textSup={comodidade.textSup}
                textSub={comodidade.textSub}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </ScrollAnimation>
    </>
  );
}
