import "animate.css/animate.min.css";
import { ButtonHypnobox } from "./components/ButtonHypnobox/ButtonHypnobox";
import { ButtonWhatsapp } from "./components/ButtonWhatsapp/ButtonWhatsapp";
import { Companies } from "./components/Companies";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { Main } from "./components/Main";
import { GlobalStyle } from "./styles/global";
export default function App() {
  return (
    <>
      <Header />
      <Main />
      <Companies />
      <Footer />
      <GlobalStyle />
      <ButtonWhatsapp />
      <ButtonHypnobox />
      {/*  <PopUp /> */}
    </>
  );
}
