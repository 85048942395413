import { Hero, Menu } from "./styles";

import { useEffect, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import logoLivingImg from "../../assets/logos/logo-dourada.png";
import blueQuadraLogo from "../../assets/logos/logo-quadra-blue.svg";
import quadraLogo from "../../assets/logos/logo-quadra.svg";
import { useFetchByContrato } from "../../services/useFetchByContrato";
import { FormMainVenture } from "../Form/Form";
import { ResponsiveNavMenu } from "../ResponsiveMenu";

export type navProps = {
  navLinks: navLink[];
};

export type navLink = {
  link: string;
  slug: string;
};

export function Header() {
  const [isScrollOnTop, setIsScrollOnTop] = useState<boolean>(true);

  const { data } = useFetchByContrato<navProps>("/nav");

  useEffect(() => {
    window.onscroll = () =>
      window.pageYOffset <= 50
        ? setIsScrollOnTop(true)
        : setIsScrollOnTop(false);
  });

  return (
    <>
      <Menu isScrollOnTop={isScrollOnTop}>
        <div className="grid-container">
          <div className="inside-grid-container">
            <div className="flex-container">
              <div className="menu-background" />
              <div className="logo-container">
                <a href="https://quadra.com.vc/">
                  {isScrollOnTop ? (
                    <img src={quadraLogo} className="logo-quadra" />
                  ) : (
                    <img src={blueQuadraLogo} className="logo-quadra-blue" />
                  )}
                </a>
              </div>
              <div className="nav-buttons-container">
                <div className="nav-links-container">
                  {data?.navLinks?.map((navLink: navLink) => (
                    <a
                      href={navLink.slug}
                      className="nav-link"
                      key={navLink.link}
                    >
                      {navLink.link}
                    </a>
                  ))}
                </div>
              </div>
              <ResponsiveNavMenu isScrollOnTop={isScrollOnTop} />
            </div>
          </div>
        </div>
      </Menu>
      <Hero>
        <div className="container-logo">
          <img src={logoLivingImg} alt="Living" />

          <p className="description">
            A essência da sofisticação é viver numa das regiões mais valorizadas
            de Belém, no bairro de Batista Campos
          </p>

          <div className="container-info">
            <button
              onClick={(e) => {
                e.preventDefault(); // Impede o comportamento padrão de navegação
                const formElement =
                  document.getElementById("form-main-venture");
                if (formElement) {
                  formElement.scrollIntoView({ behavior: "smooth" }); // Faz o scroll suave
                }
              }}
              type="submit"
              value=""
            >
              QUERO MAIS INFORMAÇÕES
            </button>
          </div>
          <div>
            <ScrollAnimation animateIn="animate__fadeIn">
              <FormMainVenture />
            </ScrollAnimation>
          </div>
        </div>
        {/* <div className="container-content">
        <div>
          <ScrollAnimation animateIn="animate__fadeIn">
            <h2>ÚLTIMAS UNIDADES DO LANÇAMENTO </h2>
            <h2>MAIS ESPERADO DE BELÉM.</h2>
          </ScrollAnimation>
          <div className="ping-element">
            <svg viewBox="0 0 14.683 22.025">
              <path
                id="location"
                d="M16.342,24.077c.534,0,1.81-1.452,2.95-4.053a22.767,22.767,0,0,0,1.944-7.752,5.126,5.126,0,0,0-4.894-5.326,5.126,5.126,0,0,0-4.894,5.326,22.772,22.772,0,0,0,1.944,7.751C14.531,22.625,15.807,24.077,16.342,24.077Zm0,2.447C12.287,26.525,9,16.567,9,12.273A7.566,7.566,0,0,1,16.342,4.5a7.566,7.566,0,0,1,7.342,7.773C23.683,16.566,20.4,26.525,16.342,26.525Zm0-12.236a2.447,2.447,0,1,0-2.447-2.447A2.447,2.447,0,0,0,16.342,14.289Z"
                transform="translate(-9 -4.5)"
                fill="#fff"
              />
            </svg>
          </div>
          <p className="container-content-description">
            A essência da sofisticação é viver numa das regiões mais valorizadas de Belém, no bairro de Batista Campos
          </p>
        </div>
      </div> */}
      </Hero>
    </>
  );
}
